(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-large.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-large.js');
"use strict";


const {
  ReactButton
} = svs.ui;
const {
  DrawCard,
  useConditionalWrapper
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_ICON_SIZE,
  DRAW_CARD_LAYOUTS,
  DRAW_CARD_TITLES,
  DRAW_STATE,
  DRAW_STATE_TEXTS
} = svs.components.tipsen.drawCard.constants;
const {
  DrawCardPanel,
  DisabledOverlay
} = svs.components.tipsen.drawCard.components;
const {
  SpeechBubble,
  SpeechBubbleSport3
} = svs.ui.ReactSpeechBubble;
const DisabledOverlayWrapper = _ref => {
  let {
    children
  } = _ref;
  return React.createElement(DisabledOverlay, {
    isHorizontal: true
  }, children);
};
const DrawCardLarge = _ref2 => {
  let {
    betEvents,
    branding,
    className,
    competitionDrawNumber,
    extraMoney,
    uniqueId,
    icon = 'game-soccer',
    netSale,
    onToggleDrawSelector,
    regCloseTime,
    shouldShowChangeDrawButton = false,
    state,
    titleElement
  } = _ref2;
  const classNames = ['pg_draw_card_large'];
  className && classNames.push(className);
  const ConditionalDisabledOverlayWrapper = useConditionalWrapper(DisabledOverlayWrapper, {
    skip: state !== DRAW_STATE.TEMPORARY_CLOSED
  });
  const isCancelled = state === DRAW_STATE.CANCELLED;
  const isNotOpened = state === DRAW_STATE.NOT_OPENED;
  const isLive = state === DRAW_STATE.IS_LIVE;
  const isFinished = state === DRAW_STATE.FINISHED;
  const drawCardTitle = competitionDrawNumber > 0 ? "".concat(DRAW_CARD_TITLES.DRAW_DESCRIPTION, " ").concat(competitionDrawNumber) : DRAW_CARD_TITLES.DRAW_DESCRIPTION;
  return React.createElement(ConditionalDisabledOverlayWrapper, null, React.createElement(DrawCard, {
    branding: branding,
    className: classNames.join(' '),
    layout: DRAW_CARD_LAYOUTS.HORIZONTAL
  }, React.createElement(DrawCardPanel, {
    title: drawCardTitle
  }, (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoneyAndJackpot) && React.createElement(SpeechBubble, {
    size: "200"
  }, React.createElement(SpeechBubbleSport3, null, "".concat(extraMoney.amountForExtraMoneyWithJackpot, " kr i extrapengar"))), React.createElement(DrawCard.Title, {
    icon: icon,
    iconSize: DRAW_CARD_ICON_SIZE.LARGE
  }, titleElement)), isLive && React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.STATE
  }, React.createElement(DrawCard.Status, {
    isLive: true
  }, "Live")), isFinished && React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.STATE
  }, React.createElement(DrawCard.Status, null, DRAW_STATE_TEXTS.FINISHED)), !isCancelled && !isLive && !isFinished && React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.BET_STOP
  }, React.createElement(DrawCard.RegCloseTime, null, regCloseTime)), isNotOpened && !isLive && !isFinished && React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.STATE
  }, React.createElement(DrawCard.Status, null, DRAW_STATE_TEXTS.NOT_OPENED)), isCancelled && React.createElement(DrawCardPanel, {
    title: DRAW_CARD_TITLES.STATE
  }, React.createElement(DrawCard.Status, {
    isCancelled: true
  }, DRAW_STATE_TEXTS.CANCELLED)), !isNotOpened && !isCancelled && netSale && React.createElement(DrawCardPanel, {
    className: "draw_card_panel__net_sale",
    title: DRAW_CARD_TITLES.NET_SALE
  }, React.createElement(DrawCard.NetSale, {
    currencyCounterUniqueId: uniqueId
  }, netSale)), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isExtraMoney) && React.createElement(DrawCardPanel, {
    className: "f-600",
    title: DRAW_CARD_TITLES.EXTRA_MONEY
  }, React.createElement(DrawCard.ExtraAmount, null, extraMoney.amount)), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isRolloverIn) && React.createElement(DrawCardPanel, {
    className: "f-600",
    title: DRAW_CARD_TITLES.ROLL_OVER_IN
  }, React.createElement(DrawCard.ExtraAmount, null, extraMoney.amount)), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isJackpot) && React.createElement(DrawCardPanel, {
    className: "f-600",
    title: DRAW_CARD_TITLES.JACKPOT
  }, React.createElement(DrawCard.ExtraAmount, null, extraMoney.amount)), (extraMoney === null || extraMoney === void 0 ? void 0 : extraMoney.isGuaranteeJackpot) && React.createElement(DrawCardPanel, null, "En ensam vinnare \xE4r garanterad ".concat(extraMoney.amount, " kr")), !shouldShowChangeDrawButton && React.createElement(DrawCardPanel, {
    className: "draw_card_panel__bet_events"
  }, React.createElement(DrawCard.BetEvents, {
    limitToNumber: 1
  }, betEvents)), shouldShowChangeDrawButton && onToggleDrawSelector && React.createElement(DrawCardPanel, {
    className: "pg_draw_card_section--exclude-from-overlay"
  }, React.createElement(DrawCard.ChangeDraw, null, React.createElement(ReactButton, {
    block: true,
    inverted: true,
    onClick: onToggleDrawSelector,
    size: 300
  }, "Byt omg\xE5ng")))));
};
setGlobal('svs.components.tipsen.drawCard.DrawCardLarge', DrawCardLarge);

 })(window);